<template>
  <div>
    <div class="img_flex">
      <el-upload
        action
        list-type="picture-card"
        :limit="5"
        accept="image/*"
        :http-request="upload"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :on-exceed="handleExceed"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div class="check" v-if="btnShow">
        <el-checkbox @change="bookShowType" :value="bookShow"
          >仅会员可看</el-checkbox
        >
      </div>
    </div>
    <div class="title">
      支持png,jpg格式，单张图片大小不能超过10MB,大图建议切图
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
import { uploadAdmin } from "@/api/common";
export default {
  props: {
    fileList: {
      type: Array,
    },
    bookShow: {
      type: Boolean,
    },
    btnShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      testList: [],
    };
  },
  methods: {
    bookShowType(val) {
      this.$emit("booean", val);
    },
    handleExceed(files, fileList) {
      this.$messageError("最多只能上传五张图片");
    },
    handleRemove(file, fileList) {
      console.log(file);
      this.$emit("removeImg", file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async upload(file) {
      // this.fileList = []

      const form = new FormData();

      form.append("files", file.file);

      const { data: res } = await uploadAdmin(form);
      if (res.code === 10200) {
        this.testList = this.fileList;
        this.testList.push({
          name: file.file.name,
          uid: file.file.uid,
          url: res.data.fileLook[0],
        });
        this.$emit("updateImg", this.testList);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-list__item {
  transition: none !important;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 88px;
  height: 88px;
  margin-bottom: 0;
  box-sizing: border-box;
}

/deep/ .el-upload--picture-card {
  width: 88px;
  height: 88px;
  line-height: 88px;
}

.title {
  line-height: 16px;
  color: #c4c4c4;
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
}

.img_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .check {
    width: 120px;
  }
}
</style>
