import axios from "axios"
import {
    message
} from "./tool"
const requestOne = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 600000
})
requestOne.interceptors.request.use(success => {
    if (sessionStorage.getItem("token")) {
        success.headers.Authorization = sessionStorage.getItem("token")
    } else {
        success.headers.Authorization = ""
    }
    return success
}, err => {
    return err
})

requestOne.interceptors.response.use(success => {
    return success
}, err => {
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                message.error("错误请求")
                break
            case 401:
                message.error("未授权，请重新登录")
                break
            case 403:
                message.error("拒绝访问")
                break
            case 404:
                message.error("请求错误，未找到该资源")
                break
            case 408:
                message.error("请求超时")
                break
            case 500:
                message.error("服务器端出错")
                break
            case 501:
                message.error("网络未实现")
                break
            case 502:
                message.error("网络错误")
                break
            case 503:
                message.error("服务不可用")
                break
            case 504:
                message.error("网络超时")
                break
            case 505:
                message.error("http版本不支持该请求")
                break
            default:
                message.error(`连接错误${err.response.status}`)
        }
    } else {
        message.error("连接到服务器失败")
    }
    return err
})

export default requestOne