import requestOne from "@/utils/copyRequest"

// 图片、文件上传
export function uploadAdminVideo(data) {
    return requestOne({
        url: "/uploadPublic/uploadVideo",
        method: "post",
        data
    })
}

//获取上传视频封面图
export function getByVideoImage(data) {
    return requestOne({
        url: '/uploadPublic/getByVideoImage',
        method: 'get',
        params: data
    })
}