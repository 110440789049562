import request from "@/utils/request"

//查询书籍所有书籍
export function getBookAll(data) {
    return request({
        url: '/books/listPage',
        method: 'get',
        params: data
    })
}

//删除单个书籍
export function deleteBook(data) {
    return request({
        url: '/books/deleteById',
        method: 'delete',
        params: data
    })
}

//上下架
export function postIsState(data) {
    return request({
        url: '/books/isState',
        method: 'post',
        data
    })
}

//新增书籍
export function addBook(data) {
    return request({
        url: '/books/save',
        method: 'post',
        data
    })
}

//编辑书籍
export function editBook(data) {
    return request({
        url: '/books/update',
        method: 'put',
        data
    })
}

//书籍查看单个
export function bookListById(data) {
    return request({
        url: '/books/listById',
        method: 'get',
        params: data
    })
}